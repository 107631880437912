import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { theme } from './theme';
import { Card, Heading, Paragraph, Button, Table, Spacer, ModalOverlay, ModalContent } from './styles/Common';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing(4)};
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusBadge = styled.span`
  background: ${({status, theme}) => {
    if (status === 'completed') return theme.colors.statusCompleted;
    if (status === 'processing') return theme.colors.statusProcessing;
    if (status === 'failed') return theme.colors.statusFailed;
    return theme.colors.border;
  }};
  color: ${({status}) => (status === 'completed' ? '#088B00' : status === 'processing' ? '#B45309' : '#9B2C2C')};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
`;

const ActionsCell = styled.td`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
`;

const FileManagement = ({ demoMode = true }) => {
  const [files, setFiles] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (demoMode) {
      // Demo mode mock data
      const mockFiles = [
        { id: 1, name: 'report_q4.pdf', status: 'completed', uploadedOn: 'Dec 1, 2024' },
        { id: 2, name: 'data_export.csv', status: 'completed', uploadedOn: 'Nov 28, 2024' },
        { id: 3, name: 'team_list.xlsx', status: 'processing', uploadedOn: 'Nov 30, 2024' }
      ];
      setFiles(mockFiles);
    } else {
      // In a real scenario, fetch the files from an API
      // fetch('/api/files').then(r=>r.json()).then(data=>setFiles(data));
    }
  }, [demoMode]);

  const handleOpenModal = () => setShowUploadModal(true);
  const handleCloseModal = () => setShowUploadModal(false);

  const handleFileUpload = (selectedFiles) => {
    // Simulate upload
    setUploading(true);
    const newFiles = Array.from(selectedFiles).map((f, idx) => ({
      id: Date.now() + idx,
      name: f.name,
      status: 'processing',
      uploadedOn: new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year:'numeric' })
    }));
    setFiles(prev => [...newFiles, ...prev]);
    
    // Simulate processing time
    setTimeout(() => {
      setUploading(false);
      handleCloseModal();
      // Simulate changing status from processing to completed
      setFiles(prev => prev.map(file => file.status === 'processing' ? {...file, status: 'completed'} : file));
    }, 2000);
  };

  const handleRemoveFile = (id) => {
    setFiles(prev => prev.filter(f => f.id !== id));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <TopBar>
          <Heading>File Management</Heading>
          <Button primary onClick={handleOpenModal}>Upload File</Button>
        </TopBar>
        <Card>
          <Table>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Status</th>
                <th>Uploaded On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map(file => (
                <tr key={file.id}>
                  <td>{file.name}</td>
                  <td><StatusBadge status={file.status}>{file.status.charAt(0).toUpperCase() + file.status.slice(1)}</StatusBadge></td>
                  <td>{file.uploadedOn}</td>
                  <ActionsCell>
                    <Button 
                      style={{background:'#FFF', borderColor:'#DC3545', color:'#DC3545'}} 
                      onClick={() => handleRemoveFile(file.id)}
                    >
                      <Icon name='trash' />
                    </Button>
                    <Button style={{background:'#FFF', borderColor:'#666', color:'#666'}}>
                      <Icon name='eye' />
                    </Button>
                  </ActionsCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>

        {showUploadModal && (
          <ModalOverlay>
            <ModalContent>
              <Heading>Upload Files</Heading>
              <Paragraph>Select one or more files to upload. They will be processed automatically.</Paragraph>
              <Spacer height="20px" />
              {!uploading ? (
                <input type="file" multiple onChange={(e) => handleFileUpload(e.target.files)} />
              ) : (
                <Paragraph>Uploading and processing...</Paragraph>
              )}
              <Spacer height="20px" />
              <Button onClick={handleCloseModal} style={{marginRight:'10px'}}>Cancel</Button>
            </ModalContent>
          </ModalOverlay>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default FileManagement;
