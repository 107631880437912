import React, { useMemo, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { CSVLink } from "react-csv";
import { ca, is } from "date-fns/locale";
import { set } from "date-fns";


const ExportCsvButton = () => {
  const [organizationData, setOrganizationData] = useState(null);
  const [outcomeQ, setOutcomeQ] = useState(null);


  const get_auth = useSelector((state) => state.auth);
  const filtered_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual
  );
  const core_data = useSelector((state) => state.audit?.core_data);
  const get_organizations = useSelector((state) => state.organizations);
  const get_survey_questions = useSelector(
    (state) => state.audit?.survey_questions
  );



  useEffect(() => {
    if (get_organizations) {
      setOrganizationData(
        get_organizations[
          get_auth?.organization_id || get_organizations?.organization?.id
        ]
      );
    }
  }, [get_organizations]);

  useEffect(() => {
    if (organizationData) {
      const sortOrder = organizationData?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;

      if (sortOrder) {
        const outcomeQ = get_survey_questions?.outcome_questions.find(
          (item) => item.sort_order === sortOrder
        );
        setOutcomeQ(outcomeQ);
      }
    }
  }, [organizationData, get_survey_questions]);

  const questionColumns = useMemo(() => {
    if (!core_data) return {};
    const columns = {};
    core_data?.questions?.dimensions?.forEach((dimension) => {
      dimension?.factors?.forEach((factor) => {
        factor?.questions?.forEach((question) => {
          if (question?.id && question?.q) {
            columns[question.id] = question.q;
          }
        });
      });
    });
    return columns;
  }, [core_data]);


  const binaryData = useMemo(() => {
    if (!core_data) return {};
    const columns = {};
    core_data?.questions?.dimensions?.forEach((dimension) => {
      dimension?.factors?.forEach((factor) => {
        factor?.questions?.forEach((question) => {
          if (question?.id && question?.q) {
            columns[question.id] = question?.binary;
          }
        });
      });
    });
    return columns;
  }, [core_data]);

  const lookUpCategory = (cat) => {
    if (!core_data) return "";
    if (cat.priority === "primary") {
      const categoryOptions = core_data.categories[0].categories?.find(
        (item) => item.id === cat.id
      )?.options;

      if("subfactors" in categoryOptions[0]){
        const subfactorOptions = categoryOptions?.find(
            (item) => item.id === cat.response
        )?.subfactors;
        return (
            subfactorOptions?.find((item) => item.id === cat.subfactor)?.name || ""
        );
    }
        return (
            categoryOptions?.find((item) => item.id === cat.response)?.name || ""
        );
    }

    if (!cat.response) {
      return "";
    }

    const categoryOptions = core_data.categories[0].categories?.find(
      (item) => item.id === cat.id
    )?.options;
    return (
      categoryOptions?.find((item) => item.id === cat.response)?.name || ""
    );
  };

  const hashString = async (str) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  };


  const csvData = useMemo(() => {
    if (!filtered_data || !core_data) return [];
    
    return filtered_data[0].map((item) => {
        const categories = {};
        item.categories.forEach((cat, index) => {
            categories[`Category ${index + 1}`] = lookUpCategory(cat);
        });

        const scores = {};
        let isChampion = false;
        item.questions.forEach((q) => {
            const questionText = questionColumns[q.ques_order];
            if (questionText) {
                scores[questionText] = q.response;
                if(binaryData[q.ques_order]){
                  isChampion = true;
                }
            }
        });

        if(isChampion){
          console.log('Champion',item.employee)
        }

        const outcomes = {};
        if (item.outcomes) {
            item.outcomes.forEach((outcome, index) => {
                const outcomeText = outcomeQ?.questions.find(
                    (q) => q.id === outcome.ques_order
                )?.q;
                if (outcomeText) {
                    outcomes[`Outcome ${index + 1} - ${outcomeText}`] = outcome.response;
                }
            });
        }

        const employee_id = item.employee
        const categories_in_org = core_data.orgCategories.find(f=>f.employee==employee_id)

        if(isChampion){
          console.log('Champion',item.employee,categories_in_org)
        }

        categories_in_org?.category.map((cat, index) => {
          const category_in_survey = item.categories.find(f=>f.id == cat.name_id)
          if(category_in_survey.response != cat.value_id && !('subfactor' in category_in_survey)){

            console.log('Mismatch',category_in_survey,cat)
          }

        })


        // Build the final row
        return {
            ...categories,
            ...scores,
            ...outcomes,
        };
    });
}, [filtered_data, questionColumns, core_data, outcomeQ]);


  const headers = useMemo(() => {
    if (csvData.length === 0) return [];
    const allKeys = Object.keys(csvData[0]);
    return allKeys.map((key) => ({
      label: key,
      key: key,
    }));
  }, [csvData]);

  if (!core_data || !filtered_data || core_data?.permissions?.account_type !== 5) {
    return <div>Loading data...</div>;
  }

  return (
    <div style={{ marginTop: 30 }}>

      <CSVLink data={csvData} headers={headers} filename="raw_scores.csv">
        <button>Export CSV</button>
      </CSVLink>

    </div>
  );
};

export default ExportCsvButton;
